require('../css/style.css');
require('../css/slider.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    loadDynamicData().then(() => simpleSlider());

    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
        }
        else if (e.target.classList.contains('faq-btn') || e.target.classList.contains('faq-title')) {
            toggleFaq(e.target, {
                collapseOthers: true
            });

            if (isMobile()) {
                const toggleArea = document.querySelector('.faq-wrapper .m-slide-down');
                const wrapper = toggleArea.querySelector('.m-slide-down-measure');
                if (toggleArea.clientHeight > 0) {
                    toggleArea.style.height = wrapper.clientHeight + 'px';
                }
            }
        }
        else if (e.target.classList.contains('tablinks')) {
            openTab(e);
        }
        else if (e.target.classList.contains('click-for-more')) {
            readMore(e);
        }
        else if (e.target.classList.contains('read-more-faq')) {

            // Read more 3

            toggleReadMore(e.target, {
                parent: '.faq-wrapper',
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                text: '.txt',
                textCollapsed: 'Show more FAQs',
                textExpanded: 'Hide FAQs',
                image: '.show-more-image'
            });
        }
        else if (e.target.classList.contains('read-more-2') || e.target.classList.contains('collapse-2')) {
            toggleReadMore(e.target, {
                parent: '.intro',
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                onCollapsed: (clicked, options) => {
                    setTimeout(() => {
                        clicked.classList.remove('m-visible');
                        clicked.classList.add('m-hidden');

                        const btnExpand = clicked.closest(options.parent).querySelector('.read-more-2');
                        btnExpand.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '...';
                        btnExpand.classList.remove('m-hidden');
                    }, 450);
                },
                onExpanded: (clicked, options) => {
                    clicked.classList.remove('m-visible');
                    clicked.classList.add('m-hidden');
                    clicked.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '';
                    clicked.closest(options.parent).querySelector('.collapse-2').classList.remove('m-hidden');
                }
            });
        }
        else if (e.target.classList.contains('icon')) {
            toggleMobileMenu();
        }
    });
}

function scrollTo(e) {

    typeof e.preventDefault === 'function' && e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);

    scrollToElement(element);

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }

    toggleMobileMenu(false);
}

function scrollToElement(element, behavior = 'smooth') {

    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
    const finalOffset = element.getBoundingClientRect().top + scrollTop;

    window.scrollTo({
        top: finalOffset,
        behavior
    });
}

function toggleMobileMenu(state) {

    const hasState = typeof state !== 'undefined';
    const header = document.querySelector('.site-header');
    const condition = hasState ? !!state : !header.classList.contains('nav-visible');

    condition
        ? header.classList.add('nav-visible')
        : header.classList.remove('nav-visible');
}

function toggleFaq(faqBtn, options = {}) {

    const faq = faqBtn.closest('.faq');
    const faqs = Array.from(faq.parentElement.children);
    const isLast = faqs.indexOf(faq) === faqs.length - 1;

    if (faq.classList.contains('collapsed')) {

        if (Object(options).collapseOthers) {
            const wrapper = faqBtn.closest('.faq-wrapper');
            const faqs = wrapper.querySelectorAll('.faq');
            if (faqs.length) {
                faqs.forEach(item => {
                    item.classList.remove('expanded');
                    item.classList.add('collapsed');
                });
            }
        }

        faq.classList.remove('collapsed');
        faq.classList.add('expanded');
    } else {
        faq.classList.remove('expanded');
        faq.classList.add('collapsed');
    }

    if (isLast) {
        scrollToElement(faq, 'auto');
    }
}

function toggleReadMore(clicked, options) {

    const toggleArea = clicked.closest(options.parent).querySelector(options.area);

    if (toggleArea.clientHeight) {
        toggleArea.style.height = 0;
        if (options.text) {
            const text = options.textCollapsed || 'Read More';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }
        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(0deg)';

        if (typeof options.onCollapsed === 'function') {
            options.onCollapsed.call(null, clicked, options);
        }
    } else {

        const wrapper = toggleArea.querySelector(options.wrapper || 'div');

        // Handle any nested read mores.
        // This will trigger when device is mobile and there is nested desktop read more.
        if (Array.isArray(options.nested)) {
            options.nested.forEach(selector => {
                const nestedAreas = wrapper.querySelectorAll(selector);
                if (nestedAreas.length) {
                    nestedAreas.forEach(nestedArea => nestedArea.style.height = 'auto');
                }
            });
        }

        toggleArea.style.height = wrapper.clientHeight + 'px';

        if (options.text) {
            const text = options.textExpanded || 'Collapse';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }

        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(180deg)';

        if (typeof options.onExpanded === 'function') {
            options.onExpanded.call(null, clicked, options);
        }
    }
}

function readMore(e) {
    e.target.previousElementSibling.classList.toggle('show-more');
    e.target.classList.toggle('show-more-btn');
}

// Tab switch
function openTab(evt) {
    var i, tabcontent, tablinks;
    tabcontent = document.querySelectorAll(".tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.querySelectorAll(".tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].classList.remove('active');
    }
    document.getElementById(evt.target.dataset.tab).style.display = "block";
    evt.target.classList.add("active");
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}

async function loadDynamicData() {

    const collections = [
        'cryptopunks',
        'boredapeyachtclub',
        'doodles-official',
        'cool-cats-nft',
        'mutant-ape-yacht-club',
        'cyberkongz',
        'cryptoadz-by-gremplin',
        'hashmasks'
    ];

    const sliderWrapper = document.querySelector('.slider');
    sliderWrapper.innerHTML = `
        <button class="slider-btn prev">❮</button>
        <div class="scroller">
            <ul class="items"></ul>
        </div>
        <button class="slider-btn next">❯</button>`;

    const items = sliderWrapper.querySelector('.items');
    const lsAvailable = lsTest();

    for (let i = 0, ii = collections.length; i < ii; i++) {

        const collectionSlug = collections[i];
        let collection = null;

        if (lsAvailable) {
            const cached = JSON.parse(localStorage.getItem(collectionSlug));
            if (cached) {
                const cachedDate = new Date(cached.date);
                const currentDate = new Date();
                if (
                    cachedDate.getFullYear() === currentDate.getFullYear() &&
                    cachedDate.getMonth() === currentDate.getMonth() &&
                    cachedDate.getDate() === currentDate.getDate()
                ) {
                    collection = cached.collection;
                }
            }
        }

        if (!collection) {
            const response = await get('https://api.opensea.io/api/v1/collection/' + collectionSlug);
            if (Object(response).collection) {

                collection = {
                    name: response.collection.name,
                    slug: collectionSlug,
                    volume: Math.round(response.collection.stats.seven_day_volume, 2).toLocaleString('en-US'),
                    img: response.collection.image_url,
                    url: response.collection.external_url ? response.collection.external_url : 'https://opensea.io/collection/' + collectionSlug
                };

                if (lsAvailable) {
                    localStorage.setItem(collectionSlug, JSON.stringify({ date: new Date(), collection }));
                }
            }
        }

        if (collection) {
            const item = document.createElement('li');
            item.classList.add('item');
            item.innerHTML = `
                <div class="item-wrapper">
                    <img src="${collection.img}" />
                    <div class="item-details">
                        <div class="item-name"><a href="${collection.url}" target="_blank" rel="noopener nofollow">${collection.name}</a></div>
                        <div class="item-stats"><b>${collection.volume} ETH</b> (7d vol.)</div>
                    </div>
                </div>`;

            items.appendChild(item);
        }
    }
}

function get(url) {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.onreadystatechange = function () {
            if (req.readyState == 4 && req.status == 200) {
                try {
                    resolve(JSON.parse(req.responseText));
                } catch (error) {
                    resolve(false);
                }
            }
        }
        req.open('GET', url, true);
        req.send(null);
    });
}

function simpleSlider() {

    var itemsCount, itemsWidth, itemWidth, itemStyle, itemMargin;
    var d = document;
    var items = d.querySelector('.slider .items');
    var item = d.querySelector('.slider .item');
    var scroller = d.querySelector('.slider .scroller');
    var buttonPrev = d.querySelector('.slider .slider-btn.prev');
    var buttonNext = d.querySelector('.slider .slider-btn.next');
    var transform = Modernizr.prefixed('transform');
    var visibleFrom = 0;
    var touchstartX = 0;
    var touchstartY = 0;
    var touchendX = 0;
    var touchendY = 0;

    function updateSliderVars() {
        itemsCount = items.children.length;
        itemsWidth = items.offsetWidth;
        itemWidth = item.offsetWidth;
        itemStyle = item.currentStyle || window.getComputedStyle(item);
        itemMargin = parseFloat(itemStyle.marginLeft) + parseFloat(itemStyle.marginRight);
    }

    function setTransform(direction = null) {

        var shownItems = 2;
        if (shownItems * itemWidth + itemMargin > itemsWidth) {
            shownItems = 1;
        }

        if (direction === true) {
            visibleFrom += shownItems;
            if (visibleFrom >= itemsCount) {
                visibleFrom -= shownItems;
                return;
            }
        } else if (direction === false) {
            visibleFrom -= shownItems;
            if (visibleFrom < 0) {
                visibleFrom = 0;
            }
        }

        var offset = (itemWidth * visibleFrom) + (itemMargin * (visibleFrom ? visibleFrom - 1 : 0));
        items.style[transform] = 'translate3d(-' + offset + 'px, 0, 0)';
    }

    function movePrev() {
        setTransform(false);
    }

    function moveNext() {
        setTransform(true);
    }

    function handleSwipe(event) {

        var caseCaptured = false;

        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 40) {
            // Swipe left
            caseCaptured = true;
            moveNext();
        } else if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 40) {
            // Swipe right
            caseCaptured = true;
            movePrev();
        }

        if (caseCaptured) {
            event.preventDefault();
        }
    }

    buttonPrev.addEventListener('click', movePrev);
    buttonNext.addEventListener('click', moveNext);

    updateSliderVars();
    window.addEventListener('resize', updateSliderVars);

    scroller.addEventListener('touchstart', (event) => {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
    }, false);

    scroller.addEventListener('touchend', (event) => {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;
        handleSwipe(event);
    }, false);
}

function lsTest() {
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}